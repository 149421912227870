import api from '../index'

const address = {
    // 获取配送地址列表
    getAddressList(data) {
        return api({
            url: 'v2/ofs/address-list',
            method: 'post',
            data: data
        })
    },
    editAddress(data) {
        return api({
            url: 'v2/ofs/address-edit',
            method: 'post',
            data: data
        })
    },
    getAddressInfo(data) {
        return api({
            url: 'v2/ofs/address-info',
            method: 'post',
            data: data
        })
    },
    getDefaultAddress(data) {
        return api({
            url: 'v2/ofs/address-default',
            method: 'post',
            data: data
        })
    },
    getOrganInfo(data) {
        return api({
            url: 'v2/ofs/organ-info',
            method: 'post',
            data: data
        })
    },

}
export default address
