<!-- 地址管理 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">地址管理</div>
    </header>
    <section class="page-info">
      <div class="info-attention">
        <van-address-list
          v-model="chosenAddressId"
          :list="list"
          :disabled-list="disabledList"
          disabled-text="以下地址超出配送范围"
          default-tag-text="默认"
          @add="onAdd"
          @edit="onEdit"
          @select="onSelect"
        />
      </div>
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import address from "@/api/ofs/address"
// import { getSession,setSession } from '@/utils/sessionUtils'
import { getStorage,setStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonData'
import { Toast } from 'vant'

export default {
  name: "addressMgr",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options != null && options.add_id != null) {
        data.chosenAddressId = parseInt(options.add_id)
      }
      getAddressList()
    });

    const data = reactive({
      chosenAddressId: 1,
      list: [],
      disabledList: [],
      organ_id: getStorage(CommonData.KeyStorages.KEY_ORGAN_ID),
    });

    const onAdd = e => {
      console.log("onAdd=>",e)
      $router.push({
        name: "editAddress"
      })
    }

    const onEdit = e => {
      console.log("onEdit=>",e)
      $router.push({
        name: "editAddress",
        query: {
          add_id: e.id
        }
      })
    }

    const onSelect = e => {
      console.log("onSelect=>",e)
      setStorage(CommonData.KeyStorages.KEY_ADD_ID, e.id)
      $router.go(-1)
    }

    const getAddressList = () => {
      address.getAddressList({}).then(res=>{
        if (res.data != null && res.data.total > 0) {
          res.data.data.map(item => {
            var address_item = {
              id: item.add_id,
              name: item.consignee,
              tel: item.phone_num,
              address: item.province + item.city + item.county + item.address + item.branch_name + item.room_number + "房-" + item.bed_number + "床",
              isDefault: item.is_default==1
            }
            if (data.organ_id == item.organ_id) {
              data.list.push(address_item)
            } else {
              data.disabledList.push(address_item)
            }
          })
        }
      })
    }

    return {
      ...toRefs(data),
      onAdd,
      onEdit,
      onSelect,
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin-bottom: 10px;
  }
}
</style>
